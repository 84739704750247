var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.tableData } },
    [
      _c("el-table-column", {
        attrs: { prop: "name", label: "渠道名称", "min-width": "40%" }
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_open",
          label: "系统默认充值渠道（唯一）",
          "min-width": "60%"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "el-switch",
                  {
                    on: {
                      change: function($event) {
                        return _vm.setSetting($event, scope.$index, scope.row)
                      }
                    },
                    model: {
                      value: scope.row.is_open,
                      callback: function($$v) {
                        _vm.$set(scope.row, "is_open", $$v)
                      },
                      expression: "scope.row.is_open"
                    }
                  },
                  [_vm._v(" " + _vm._s(scope.row.is_open) + " ")]
                ),
                scope.row.is_open
                  ? _c(
                      "span",
                      { staticStyle: { color: "#409EFF", margin: "0 20px" } },
                      [_vm._v("开")]
                    )
                  : _c("span", { staticStyle: { margin: "0 20px" } }, [
                      _vm._v("关")
                    ]),
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.setCompany },
                    model: {
                      value: scope.row.open_gw,
                      callback: function($$v) {
                        _vm.$set(scope.row, "open_gw", $$v)
                      },
                      expression: "scope.row.open_gw"
                    }
                  },
                  [_vm._v("国网电力公司")]
                ),
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.setCompany },
                    model: {
                      value: scope.row.open_nw,
                      callback: function($$v) {
                        _vm.$set(scope.row, "open_nw", $$v)
                      },
                      expression: "scope.row.open_nw"
                    }
                  },
                  [_vm._v("南网电力公司")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }